// Colors
$background-color: #232324; // #23241f; // #272727;
$foreground-color: #999999; //#8c8c8c;// #747474;

$color-pallete-white: #e8f7ee;
$color-pallete-grey: #8e9691;
$color-pallete-yellow: #FFD151;
$color-pallete-yellow-dark: #FFD151; //#ccb735;
$color-pallete-red: #e56f40;
$color-pallete-orange: #e56f40;
$color-pallete-pink: #eb0085;
$color-pallete-light-green: #9bf116;
$color-pallete-green: #17ad6b;
$color-pallete-light-blue: #3bd0de;
$color-pallete-blue: #0e90ec;
$color-pallete-dark-blue: #1465b1;
$color-pallete-purple: #a172ec;

// Colour blended
$color-pallete-grey-blue: #4e6769;

// Color short-list
// #e8f7ee honey due (white)
// #f5f0e7 alabaster (white)
// #fff5aa navajo white
// #d36135 medium vermilion
// #ff652f portland orange
// #f18f01 tangerine
// #fabc3c maximum yellow red
// #ffeb45 gargoyle gas
// #ffe400 vivid yellow
// #fff587 yellow crayola
// #e8df7b flax
// #16db65 malachite
// #FFD151 mustard
// #1b998b jungle green
// #17ad6b crayola green
// #8fd694 light green
// #7ed684 pastel green
// #c5d86d june bud (green)
// #034078 dark cerulean (blue)


// Sizes
$xxxl-size: 5.4rem;
$xxl-size: 4.2rem;
$xl-size: 3.8rem;
$l-size: 2.8rem;
$m-size: 2.4rem;
$xm-size: 2.0rem;
$s-size: 1.8rem;
$xs-size: 1.4rem;
