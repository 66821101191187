.code-repo {
  margin-bottom: 3rem;
}

.code-repo__title {
  color: lighten($foreground-color, 30%);
}

.code-repo__description {
  font-size: $xs-size;
}

.code-repo__description__text {
  margin-left: 1rem;
}
