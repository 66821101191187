@import url("https://fonts.googleapis.com/css?family=Noto+Sans+HK:regular&display=swap");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+HK:bold&display=swap");
html {
  font-size: 62.5%;
}

body {
  background-color: #232324;
  color: #999999;
  font-family: "Noto Sans HK", sans-serif !important;
  font-size: 1.8rem;
  -webkit-font-feature-settings: "kern" 1, "liga" 1, "calt" 1;
  -moz-font-feature-settings: "kern" 1, "liga" 1, "calt" 1;
  font-feature-settings: "kern" 1, "liga" 1, "calt" 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 3.8rem;
}

h2 {
  font-size: 2.4rem;
}

a {
  outline: 0;
  color: #999999;
}

a:hover {
  text-decoration: none !important;
  color: #FFD151 !important;
  border-bottom: 0.2rem solid #FFD151;
}

.section-title {
  color: #b3b3b3;
  font-weight: bold;
  margin-bottom: 3rem;
}

.hidden {
  visibility: hidden;
}

strong {
  font-weight: 400;
}

html, body, #app, #app > div, #app > div > div.home-page, #app > div > div.home-page > .container {
  height: 100%;
}

.content {
  margin-top: 11rem;
}

nav {
  background: #232324;
  -webkit-box-shadow: 0px -6px 12px 3px black;
  -moz-box-shadow: 0px -6px 12px 3px black;
  box-shadow: 0px -6px 12px 3px black;
}

.navbar-nav {
  align-items: center;
}

.nav-item {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.header__link {
  color: #999999 !important;
  margin: 1rem;
}

.header__image {
  border-radius: 50%;
  margin: 0.5rem 0.5rem;
}

.is-active {
  color: #e6e6e6 !important;
  border-bottom: 0.2rem solid #e6e6e6;
}

a:hover {
  text-decoration: none !important;
  color: #FFD151 !important;
  border-bottom: none;
}

.navbar-toggler {
  border-color: #999999 !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(116, 116, 116, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.banner__title {
  font-size: 5.4rem;
}

.banner__subtitle {
  font-size: 2.8rem;
  margin-top: 2rem;
}

.banner__subtitle > span > div > h2 {
  margin-top: 2rem;
  margin-bottom: 0;
}

.banner__highlight {
  color: #e6e6e6;
}

.banner__strikethrough {
  text-decoration: line-through;
}

.banner__code {
  margin-left: 22rem;
}

.python {
  color: #17ad6b;
}

.js {
  color: #FFD151;
}

.sql {
  color: #a172ec;
}

.c {
  color: #0e90ec;
}

.spark {
  color: #e56f40;
}

.react {
  color: #3bd0de;
}

.node {
  color: #9bf116;
}

.sub-banner {
  margin-top: 7rem;
}

.sub-banner__title {
  font-size: 2rem;
  line-height: 3.5rem;
}

.sub-banner__subtitle {
  margin-top: 2rem;
  font-size: 2rem;
  line-height: 3.5rem;
}

.sub-banner__highlight {
  color: #e6e6e6;
}

.fadein-banner {
  animation: fadein 2s;
  -moz-animation: fadein 2s;
  -webkit-animation: fadein 2s;
  -o-animation: fadein 2s;
}

.fadein-subbanner {
  animation: fadein 4s;
  -moz-animation: fadein 4s;
  -webkit-animation: fadein 4s;
  -o-animation: fadein 4s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (min-width: 992px) {
  .home-page-large-screen {
    display: block;
  }

  .home-page-small-screen {
    display: none;
  }
}
@media (max-width: 992px) {
  .home-page-large-screen {
    display: none;
  }

  .home-page-small-screen {
    display: block;
  }
}
@media (max-width: 768px) {
  .sub-banner {
    margin-top: 5rem !important;
  }
}
@media (max-width: 400px) {
  .banner__subtitle__container {
    display: none;
  }
}
.bio__link {
  color: #bfbfbf;
  border-bottom: 0.2rem solid #999999;
}

.bio__banner__title {
  font-size: 4.2rem;
}

.bio__sub-banner__title {
  font-size: 2.4rem;
}

.bio__section {
  border-radius: 7px;
}

.bio__section__padding {
  margin-top: 5rem;
}

.bio__section__title {
  font-size: 2.4rem;
}

.bio__section__title__highlight {
  color: #e6e6e6;
}

.bio__section__highlight {
  color: #FFD151;
}

.bio__section__row {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #dee2e6;
}

.bio__section-one {
  margin-top: 5rem;
  padding: 2rem;
  background-color: #17ad6bd4;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bio__section-one__title {
  font-size: 2.4rem;
  margin-bottom: 3rem;
}

.bio__section-one__title__highlight {
  color: #e6e6e6;
}

.bio__section-one__highlight {
  color: #FFD151;
}

.bio__section-one__row {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #dee2e6;
}

.bio__section-two {
  margin-top: 5rem;
  padding: 2rem;
  background-color: #0e90ecc4;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bio__section-two__title {
  font-size: 2.4rem;
  margin-bottom: 3rem;
}

.bio__section-two__title__highlight {
  color: #e6e6e6;
}

.bio__section-two__highlight {
  color: #FFD151;
}

.bio__section-two__row {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #dee2e6;
}

.bio__section-three {
  margin-top: 5rem;
  padding: 2rem;
  background-color: #e56f40;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bio__section-three__title {
  font-size: 2.4rem;
  margin-bottom: 3rem;
}

.bio__section-three__title__highlight {
  color: #e6e6e6;
}

.bio__section-three__highlight {
  color: #FFD151;
}

.bio__section-three__row {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #dee2e6;
}

.client__banner__title {
  font-size: 2rem;
  line-height: 2;
}

.client__section {
  margin-top: 5rem;
}

.client__logo {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #999999;
  background-color: #e6e6e6;
}

.blog-post {
  margin-bottom: 3rem;
}

.blog-post__title {
  color: #e6e6e6;
}

.blog-post__date-value {
  font-size: 1.4rem;
  margin-left: 1rem;
}

.blog-post__tags-value {
  font-size: 1.4rem;
  margin-left: 1rem;
}

.post__title {
  margin-bottom: 2rem;
  color: #e6e6e6;
}

.post__date {
  margin-bottom: 7rem;
  color: #999999;
}

.post__content {
  margin-bottom: 6rem;
}

.post__content h2, h3, h4, h5, h6 {
  margin-bottom: 1.5rem;
  color: #e6e6e6;
  line-height: 2;
}

.post__content p {
  margin-bottom: 2rem;
  line-height: 2;
}

.post__content a {
  color: #bfbfbf;
  border-bottom: 0.2rem solid #999999;
}

.post__content a:hover {
  text-decoration: none !important;
  color: #FFD151 !important;
  border-bottom: 0.2rem solid #FFD151;
}

.post__content ul, ol {
  margin-bottom: 2rem;
}

.post__content li {
  margin-bottom: 1rem;
  line-height: 2;
}

.post__content blockquote {
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-inline-start: 40px;
  padding-inline-end: 40px;
  font-style: italic;
  line-height: 2;
}

.post__content strong {
  color: #cccccc;
}

.post__content img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #999999;
}

.post__content hr {
  margin-top: 5rem;
  margin-bottom: 5rem;
  width: 75%;
  border-top: 1px solid #999999;
}

.post__content pre {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.post__content pre > code {
  padding: 2rem;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #999999;
}

@media (min-width: 992px) {
  .post__width {
    max-width: 700px;
  }
}
.code-repo {
  margin-bottom: 3rem;
}

.code-repo__title {
  color: #e6e6e6;
}

.code-repo__description {
  font-size: 1.4rem;
}

.code-repo__description__text {
  margin-left: 1rem;
}