html {
    // Used to have a 1:10 ratio between rem's and pixels
    font-size: 62.5%;
}

body {
    background-color: $background-color;
    color: $foreground-color;

    font-family: 'Noto Sans HK', sans-serif !important;
    font-size: $s-size;

    -webkit-font-feature-settings: "kern" 1, "liga" 1, "calt" 1;
    -moz-font-feature-settings: "kern" 1, "liga" 1, "calt" 1;
    font-feature-settings: "kern" 1, "liga" 1, "calt" 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

h1 {
    font-size: $xl-size;
}

h2 {
    font-size: $m-size;
}

a {
    outline: 0;
    color: $foreground-color;
}

// Override default hover to remove underline
a:hover {
    text-decoration: none !important;
    color: $color-pallete-yellow !important;
    border-bottom: 0.2rem solid $color-pallete-yellow;
}

.section-title {
    color: lighten($foreground-color, 10%);
    font-weight: bold;
    margin-bottom: 3rem;
}

// Used for laying-out the header properly
.hidden {
    visibility: hidden;
}

// Used for displaying fonts properly on Safari and Firefox
strong {
    font-weight: 400;
}

html, body, #app, #app>div, #app>div>div.home-page, #app>div>div.home-page>.container  {
    height: 100%
}
