.banner__title {
  font-size: $xxxl-size;
}

.banner__subtitle {
  font-size: $l-size;
  margin-top: 2rem;
}

// To capture code animation
.banner__subtitle > span > div > h2 {
  margin-top: 2rem;
  margin-bottom: 0;
}

.banner__highlight {
  color: lighten($foreground-color, 30%);
}

.banner__strikethrough {
  text-decoration: line-through;
}

.banner__code {
  margin-left: 22rem;
}

.python {
  color: $color-pallete-green;
}

.js {
  color: $color-pallete-yellow;
}

.sql {
  color: $color-pallete-purple;
}

.c {
  color: $color-pallete-blue;
}

.spark {
  color: $color-pallete-orange;
}

.react {
  color: $color-pallete-light-blue;
}

.node {
  color: $color-pallete-light-green;
}


.sub-banner {
  margin-top: 7rem;
}

.sub-banner__title {
  font-size: $xm-size;
  line-height: 3.5rem;
}

.sub-banner__subtitle {
  margin-top: 2rem;
  font-size: $xm-size;
  line-height: 3.5rem
}

.sub-banner__highlight {
  color: lighten($foreground-color, 30%);
}

.fadein-banner {
  animation: fadein 2s;
  -moz-animation: fadein 2s;
  -webkit-animation: fadein 2s;
  -o-animation: fadein 2s;
}

.fadein-subbanner {
  animation: fadein 4s;
  -moz-animation: fadein 4s;
  -webkit-animation: fadein 4s;
  -o-animation: fadein 4s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


// Adapt to different screen sizes
@media (min-width: 992px) {
  .home-page-large-screen {
    display: block;
  }

  .home-page-small-screen {
    display: none;
  }
}

@media (max-width: 992px) {
  .home-page-large-screen {
    display: none;
  }

  .home-page-small-screen {
    display: block;
  }
}

@media (max-width: 768px) {
  .sub-banner {
    margin-top: 5rem !important;
  }

}

@media (max-width: 400px) {
  .banner__subtitle__container {
    display: none;
  }
}
