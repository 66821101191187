nav {
    background: $background-color;

    // shadow below header
    -webkit-box-shadow: 0px -6px 12px 3px rgba(0,0,0,1);
    -moz-box-shadow: 0px -6px 12px 3px rgba(0,0,0,1);
    box-shadow: 0px -6px 12px 3px rgba(0,0,0,1);
}

.navbar-nav {
    align-items: center;
}

.nav-item {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.header__link {
    color: $foreground-color !important;
    margin: 1rem;
}

.header__image {
    border-radius: 50%;
    margin: 0.5rem 0.5rem;
}

.is-active {
    color: lighten($foreground-color, 30%) !important;
    border-bottom: 0.2rem solid lighten($foreground-color, 30%);
}

// Override default hover to remove underline
a:hover {
    text-decoration: none !important;
    color: $color-pallete-yellow !important;
    border-bottom: none;
}



// Needed to make the responsive navbar toggle icon
// to show in a color other than black
.navbar-toggler {
    border-color: $foreground-color !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(116, 116, 116, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
